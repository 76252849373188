.data-table-extensions {
  /* position: absolute;
        max-width: 100% !important; */
}
.jPffGe {
  position: relative !important;
}

header {
  display: none;
}

input[type='checkbox']:checked {
  filter: hue-rotate(240deg);
}

.jumbotron {
  padding: 1rem 1rem !important;
}
