.abcd {
  position: relative !important;
}

.rdt_TableCol_Sortable {
  font-weight: bold;
}

#order_items .rdt_TableRow {
  max-height: 120px !important;
}
